export const getIconForPrelevement = (typePrelevement: string) => {
  if (typePrelevement.toLowerCase().indexOf("biopsie") > -1)
    return "/assets/images/prelevement.svg";
  if (typePrelevement.toLowerCase().indexOf("salivaire") > -1)
    return "/assets/images/salivaire.svg";
  if (
    typePrelevement.toLowerCase().indexOf("salive") > -1 ||
    typePrelevement.toLowerCase().indexOf("nasopharyngé") > -1 ||
    typePrelevement.toLowerCase().indexOf("vaginal") > -1 ||
    typePrelevement.toLowerCase().indexOf("auto-prélèvement vaginal") > -1
  )
    return "/assets/images/pipette.svg";

  if (
    typePrelevement.toLowerCase().indexOf("urinaire") > -1 ||
    typePrelevement.toLowerCase().indexOf("fécal") > -1
  )
    return "/assets/images/tube.svg";

  return "/assets/images/prelevement.svg";
};
