import React, { useEffect } from "react";
import { UIStage, useExpertiseStore } from "../store/expertises.store";
import { Category } from "../models/category";
import { Expertise } from "../models/expertise";

type Props = {
  currentStage: UIStage;
  selectedCategory: Category;
  selectCategory: (category: Category) => void;
  selectedFamille: string;
  selectFamille: (famille: string) => void;
  selectedExpertise: Expertise;
  reset: () => void;
  setCurrentStage: (stage: UIStage) => void;
};

export default function Breadcrumbs({
  currentStage,
  selectedCategory,
  selectCategory,
  selectedFamille,
  selectFamille,
  selectedExpertise,
  reset,
  setCurrentStage,
}: Props) {
  const [lastCategory, setLastCategory] = React.useState<Category | null>(null);
  const [lastFamille, setLastFamille] = React.useState<string | null>(null);

  useEffect(() => {
    if (currentStage == UIStage.Category) {
      setLastCategory(selectedCategory);
    }
  }, [currentStage]);

  useEffect(() => {
    if (currentStage == UIStage.Famille) {
      setLastFamille(selectedFamille);
    }
  }, [currentStage]);

  return (
    currentStage != UIStage.Home && (
      // Display the breadcrumb
      <div className="mb-4 text-md flex justify-center items-center flex-wrap ">
        <h2
          onClick={() => reset()}
          className="text-white text-[20px] inline cursor-pointer"
        >
          <strong>{selectedCategory.Name}</strong>
        </h2>

        {selectedFamille && (
          <>
            <span className="text-white ml-4">/</span>
            <button
              onClick={() => {
                reset();
                selectCategory(lastCategory || selectedCategory);
                setCurrentStage(UIStage.Category);
              }}
              className="text-white ml-4"
            >
              {selectedFamille}
            </button>
          </>
        )}
        {currentStage == UIStage.Expertise && (
          <>
            <span className="text-white ml-4">/</span>
            <span>
              <strong className="text-orange-500 ml-4">
                {selectedExpertise.Titre}
              </strong>
            </span>
          </>
        )}
      </div>
    )
  );
}
