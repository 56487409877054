import React, { useEffect } from "react";
import RegionButton from "./region-button";

export default function Regions({ onSelectedDepartement }) {
  const departements = {
    "Ain (01)": "Auvergne-Rhône-Alpes",
    "Aisne (02)": "Hauts-de-France",
    "Allier (03)": "Auvergne-Rhône-Alpes",
    "Alpes-de-Haute-Provence (04)": "Provence-Alpes-Côte d'Azur",
    "Hautes-Alpes (05)": "Provence-Alpes-Côte d'Azur",
    "Alpes-Maritimes (06)": "Provence-Alpes-Côte d'Azur",
    "Ardèche (07)": "Auvergne-Rhône-Alpes",
    "Ardennes (08)": "Grand Est",
    "Ariège (09)": "Occitanie",
    "Aube (10)": "Grand Est",
    "Aude (11)": "Occitanie",
    "Aveyron (12)": "Occitanie",
    "Bouches-du-Rhône (13)": "Provence-Alpes-Côte d'Azur",
    "Calvados (14)": "Normandie",
    "Cantal (15)": "Auvergne-Rhône-Alpes",
    "Charente (16)": "Nouvelle-Aquitaine",
    "Charente-Maritime (17)": "Nouvelle-Aquitaine",
    "Cher (18)": "Centre-Val de Loire",
    "Corrèze (19)": "Nouvelle-Aquitaine",
    "Corse-du-Sud (2A)": "Corse",
    "Haute-Corse (2B)": "Corse",
    "Côte-d'Or (21)": "Bourgogne-Franche-Comté",
    "Côtes d'Armor (22)": "Bretagne",
    "Creuse (23)": "Nouvelle-Aquitaine",
    "Dordogne (24)": "Nouvelle-Aquitaine",
    "Doubs (25)": "Bourgogne-Franche-Comté",
    "Drôme (26)": "Auvergne-Rhône-Alpes",
    "Eure (27)": "Normandie",
    "Eure-et-Loir (28)": "Centre-Val de Loire",
    "Gard (30)": "Occitanie",
    "Haute-Garonne (31)": "Occitanie",
    "Gers (32)": "Occitanie",
    "Gironde (33)": "Nouvelle-Aquitaine",
    "Hérault (34)": "Occitanie",
    "Ille-et-Vilaine (35)": "Bretagne",
    "Indre (36)": "Centre-Val de Loire",
    "Indre-et-Loire (37)": "Centre-Val de Loire",
    "Isère (38)": "Auvergne-Rhône-Alpes",
    "Jura (39)": "Bourgogne-Franche-Comté",
    "Landes (40)": "Nouvelle-Aquitaine",
    "Loir-et-Cher (41)": "Centre-Val de Loire",
    "Loire (42)": "Auvergne-Rhône-Alpes",
    "Haute-Loire (43)": "Auvergne-Rhône-Alpes",
    "Loire-Atlantique (44)": "Pays de la Loire",
    "Loiret (45)": "Centre-Val de Loire",
    "Lot (46)": "Occitanie",
    "Lot-et-Garonne (47)": "Nouvelle-Aquitaine",
    "Lozère (48)": "Occitanie",
    "Maine-et-Loire (49)": "Pays de la Loire",
    "Manche (50)": "Normandie",
    "Marne (51)": "Grand Est",
    "Haute-Marne (52)": "Grand Est",
    "Mayenne (53)": "Pays de la Loire",
    "Meurthe-et-Moselle (54)": "Grand Est",
    "Meuse (55)": "Grand Est",
    "Morbihan (56)": "Bretagne",
    "Moselle (57)": "Grand Est",
    "Nièvre (58)": "Bourgogne-Franche-Comté",
    "Nord (59)": "Hauts-de-France",
    "Oise (60)": "Hauts-de-France",
    "Orne (61)": "Normandie",
    "Pas-de-Calais (62)": "Hauts-de-France",
    "Puy-de-Dôme (63)": "Auvergne-Rhône-Alpes",
    "Pyrénées-Atlantiques (64)": "Nouvelle-Aquitaine",
    "Hautes-Pyrénées (65)": "Occitanie",
    "Pyrénées-Orientales (66)": "Occitanie",
    "Bas-Rhin (67)": "Grand Est",
    "Haut-Rhin (68)": "Grand Est",
    "Rhône (69)": "Auvergne-Rhône-Alpes",
    "Haute-Saône (70)": "Bourgogne-Franche-Comté",
    "Saône-et-Loire (71)": "Bourgogne-Franche-Comté",
    "Sarthe (72)": "Pays de la Loire",
    "Savoie (73)": "Auvergne-Rhône-Alpes",
    "Haute-Savoie (74)": "Auvergne-Rhône-Alpes",
    "Paris (75)": "Île-de-France",
    "Seine-Maritime (76)": "Normandie",
    "Seine-et-Marne (77)": "Île-de-France",
    "Yvelines (78)": "Île-de-France",
    "Deux-Sèvres (79)": "Nouvelle-Aquitaine",
    "Somme (80)": "Hauts-de-France",
    "Tarn (81)": "Occitanie",
    "Tarn-et-Garonne (82)": "Occitanie",
    "Var (83)": "Provence-Alpes-Côte d'Azur",
    "Vaucluse (84)": "Provence-Alpes-Côte d'Azur",
    "Vendée (85)": "Pays de la Loire",
    "Vienne (86)": "Nouvelle-Aquitaine",
    "Haute-Vienne (87)": "Nouvelle-Aquitaine",
    "Vosges (88)": "Grand Est",
    "Yonne (89)": "Bourgogne-Franche-Comté",
    "Territoire de Belfort (90)": "Bourgogne-Franche-Comté",
    "Essonne (91)": "Île-de-France",
    "Hauts-de-Seine (92)": "Île-de-France",
    "Seine-St-Denis (93)": "Île-de-France",
    "Val-de-Marne (94)": "Île-de-France",
    "Val-D'Oise (95)": "Île-de-France",
    "Réunion (974)": "La Réunion",
    "Guadeloupe (971)": "France d'outre-mer",
    "Guyane (973)": "Guyane (973)",
    "Martinique (972)": "Martinique (972)",
    "Mayotte (976)": "France d'outre-mer",
    "Finistère (29)": "Bretagne",
  };

  const rawRegions = [
    ...new Set(
      serveursResultats.reduce(
        (acc, item) => acc.concat(item.Region.split(",")),
        []
      )
    ),
  ];
  const regions = rawRegions
    .map((x) => {
      if (x.indexOf("(") > -1) {
        return departements[x];
      }
      return x;
    })
    .filter((x, i, a) => a.indexOf(x) === i);

  const departementsParRegion = (region) => {
    const deps = Object.keys(departements).filter(
      (x) => departements[x] === region
    );

    // Remove any departements that aren't in the list of serveursResultats
    const filteredDeps = deps.filter((x) =>
      serveursResultats.some((y) => y.Region.includes(x))
    );

    return filteredDeps.sort( (a, b) => {
      const aName = a.split(" (")[0];
      const bName = b.split(" (")[0];
      if (aName < bName) return -1;
      if (aName > bName) return 1;
      return 0;
    });
  };

  const [selectedRegion, setSelectedRegion] = React.useState(null);
  const [selectedDepartement, setSelectedDepartement] = React.useState(null);

  const handleRegionClick = (region) => {
    const departementsInRegion = departementsParRegion(region);
    
    console.log(region, {departementsInRegion})
    if (departementsInRegion.length <= 1) {
      // If no departements are found for this region, check for a direct match in serveursResultats
      const directMatch = serveursResultats.find((x) => x.Region === region);
      if (directMatch) {
        window.location.href = directMatch.Lien.Url;
        return;
      }
    }

    setSelectedRegion(region);
    setSelectedDepartement(null);
  };

  const handleDepartementClick = (departement) => {
    // setSelectedDepartement(departement);
    // onSelectedDepartement && onSelectedDepartement(departement);
    // Let's instead go directly to the first result for the selected departement
    const directMatch = serveursResultats.find((x) => x.Region.includes(departement));
    if (directMatch) {
      window.location.href = directMatch.Lien.Url;
    }
  };

  const handleBackClick = () => {
    setSelectedDepartement(null);
    setSelectedRegion(null);
    setCurrentServeurResultats([]);
  };

  const [currentServeurResultats, setCurrentServeurResultats] = React.useState(
    []
  );

  useEffect(() => {

    if (selectedRegion || selectedDepartement) {
      const results = serveursResultats.filter((x) => {


        return (
          (selectedRegion && x.Region.includes(selectedRegion)) ||
          (selectedDepartement && x.Region.includes(selectedDepartement))
        );
      });

      setCurrentServeurResultats(results);
    }
  }, [selectedRegion, selectedDepartement]);

  const formatName = (name) => {
    // If Guyane, Martinique, or La Réunion, return the name as is without the parentheses
    if (name === "Guyane (973)" || name === "Martinique (972)" || name === "La Réunion") {
      const withoutParens = name.split(" (")[0];
      return withoutParens;
    }
    return name;
  }

  return (
    <div className="relative mx-auto max-w-[1200px]">
      
      <h2 className="mb-4 text-[20px] text-white">
        {selectedRegion && (
          <button
            className="text-orange-500"
            onClick={handleBackClick}
          >
            &lt; Retour
          </button>
        )}
        {selectedDepartement && " | "}
        {selectedDepartement || ""}
      </h2>

      {currentServeurResultats.length === 0 && (
        <div className="grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-4">
          {!selectedRegion &&
            regions.map((x, i) => (
              <RegionButton
                title={ formatName(x) }
                callback={() => handleRegionClick(x)}
                key={`region-${i}`}
              />
            ))}

          {selectedRegion &&
            departementsParRegion(selectedRegion).map((x, i) => (
              <RegionButton
                title={ formatName(x) }
                key={`departement-${i}`}
                callback={() => handleDepartementClick(x)}
              />
            ))}
        </div>
      )}

      {currentServeurResultats.length > 0 && (
        <div className="flex items-stretch gap-6">
          {currentServeurResultats.map((serveur, index) => (
            <div
              className="flex-1 rounded-lg bg-white p-4 pb-[40px]"
              key={`serveur-${index}`}
            >
              <h3>
                <span>{serveur.Name}</span>
              </h3>
              <p
                className="pt-3"
                dangerouslySetInnerHTML={{
                  __html: serveur.Contenu,
                }}
              ></p>
              {serveur.Lien && (
                <a
                  href={serveur.Lien.Url}
                  target={serveur.Lien.Target}
                  className="cta orange"
                >
                  Accéder au serveur de résultats
                  {serveur.Lien.Target === "_blank" && (
                    <i className="fas fa-external-link-alt ml-2"></i>
                  )}
                </a>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
