import { create } from "zustand";
import { Expertise } from "../models/expertise";
import { Category } from "../models/category";
import { Home } from "../models/home";

export enum UIStage {
  Home = "Home",
  Category = "Category",
  Famille = "Famille",
  Expertise = "Expertise",
}

type ExpertiseStore = {
  selectedCategory: Category;
  setSelectedCategory: (category: Category) => void;

  categories: Category[];
  setCategories: (categories: Category[]) => void;

  parentPageModel: Home;
  setParentPageModel: (parentPageModel: Home) => void;

  expertises: Expertise[];
  setExpertises: (expertises: Expertise[]) => void;

  selectedExpertise: Expertise;
  setSelectedExpertise: (expertise: Expertise) => void;

  selectedFamille: string;
  setSelectedFamille: (famille: string) => void;

  currentStage: UIStage;
  setCurrentStage: (stage: UIStage) => void;
};

export const useExpertiseStore = create<ExpertiseStore>((set) => ({
  selectedCategory: {} as Category,
  setSelectedCategory: (category: Category) =>
    set({ selectedCategory: category }),

  categories: [],
  setCategories: (categories: Category[]) => set({ categories }),

  parentPageModel: {} as Home,
  setParentPageModel: (parentPageModel: Home) => set({ parentPageModel }),

  expertises: [],
  setExpertises: (expertises: Expertise[]) => set({ expertises }),

  selectedExpertise: {} as Expertise,
  setSelectedExpertise: (expertise: Expertise) =>
    set({ selectedExpertise: expertise }),

  selectedFamille: "",
  setSelectedFamille: (famille: string) => set({ selectedFamille: famille }),

  currentStage: UIStage.Home,
  setCurrentStage: (stage: UIStage) => set({ currentStage: stage }),
}));
