import React, { useEffect, useRef, useState } from "react";
import { distance2D } from "framer-motion";

export default function ResultatsContainer({ children }) {
  const [containerSize, setContainerSize] = useState({ width: 0, height: 0 });
  const rectSize = 350;
  const matrixSpacing = 14;

  const containerRef = useRef();
  const canvasRef = useRef();
  let frameIndex = 0;

  // Setup spring for x and y positions

  const springX = useRef(0);
  const springY = useRef(0);

  const updateContainerSize = () => {
    setContainerSize({
      width: containerRef.current.clientWidth,
      height: containerRef.current.clientHeight,
    });
  };

  const drawMatrix = (x, y) => {
    frameIndex++;

    if(frameIndex > 20) {
      updateContainerSize();
      frameIndex = 0;
    }
    

    const canvas = canvasRef.current;
    if (!canvas) return;

    const ctx = canvas.getContext("2d");
    ctx.clearRect(0, 0, canvas.width, canvas.height); // Clear previous drawing

    for (let i = -rectSize / 2; i < rectSize / 2; i += matrixSpacing) {
      for (let j = -rectSize / 2; j < rectSize / 2; j += matrixSpacing) {
        const dotX = x + i - (x % matrixSpacing);
        const dotY = y + j - (y % matrixSpacing);

        const distance = matrixSpacing - Math.pow(distance2D({ x, y }, { x: dotX, y: dotY }), 0.54);
        const size = Math.max(distance, 0);

        ctx.beginPath();
        ctx.arc(dotX, dotY, size / 2, 0, 2 * Math.PI);
        ctx.fillStyle = `rgba(255, 255, 255, ${0.4 + size / 40})`;
        ctx.fill();
      }
    }
  };

  useEffect(() => {
    const handleMouseMove = (e) => {
      const rect = containerRef.current.getBoundingClientRect();
      springX.current = e.clientX - rect.left;
      springY.current = e.clientY - rect.top;
    };

    document.addEventListener("mousemove", handleMouseMove);

    

    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
    };
  }, []);

  useEffect(() => {
    const animate = () => {
      drawMatrix(springX.current, springY.current);
      requestAnimationFrame(animate);
    };
    const id = requestAnimationFrame(animate);

    return () => cancelAnimationFrame(id);
  }, []);

  return (
    <div ref={containerRef} className="degradedark resultatscontainer relative mb-8 flex items-center bg-darkblue-500 py-20">
      <canvas ref={canvasRef} width={containerSize.width} height={containerSize.height} className="absolute inset-0"></canvas>
      <div className="container mx-auto px-4">
        {children}</div>
    </div>
  );
}
