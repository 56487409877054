import React from "react";

function getNoteImages(rating) {
  const fullNotes = Math.floor(rating);
  const partialNote = rating - fullNotes;
  let noteImages = Array(fullNotes).fill("full");
  if (partialNote >= 0.75) {
    noteImages.push("threequarters");
  } else if (partialNote >= 0.5) {
    noteImages.push("half");
  } else if (partialNote >= 0.25) {
    noteImages.push("quarter");
  }
  const remainingNotes = Math.max(0, 5 - noteImages.length);
  for (let i = 0; i < remainingNotes; i++) {
    noteImages.push("empty");
  }
  return noteImages;
}

const ErfGoogleRating = ({ rating, total, googlePlaceId }) => {
  const noteImages = getNoteImages(rating);
  const formattedRating = Number(rating).toFixed(1);

  const url = `https://search.google.com/local/reviews?placeid=${googlePlaceId}`;

  return (
    <a
      className="inline-flex items-center gap-[2px]"
      href={url}
      target="_blank"
      rel="noreferrer"
    >
      <i className="fa-brands fa-google"></i>
      <span className="sr-only">
        {rating} {rating > 1 ? "stars" : "star"}
      </span>
      <strong className="pl-2">{formattedRating}</strong>

      <div className="inline-flex items-center gap-[2px] pl-2">
        {noteImages.map((note, index) => (
          <img
            key={index}
            className="w-[19px] h-[19px]"
            src={`/assets/images/note/${note}.svg`}
            alt="note"
          />
        ))}
      </div>

      <strong className="pl-1 font-normal text-sm">({total} avis)</strong>
    </a>
  );
};

export default ErfGoogleRating;
