import React, { useState, useEffect, useRef, useMemo } from "react";

function SearchLocationInput({ className, placeholder, onChange }) {
  const [query, setQuery] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [isWoosmapLoaded, setIsWoosmapLoaded] = useState(false);
  const autoCompleteRef = useRef(null);
  const clearButtonRef = useRef(null);
  const lastQueryRef = useRef("");
  const ignoreNextQueryChangeRef = useRef(false);

  useEffect(() => {
    const checkWoosmapLoaded = () => {
      if (window.woosmap && window.woosmap.localities && localStorage.woosmapKey) {
        setIsWoosmapLoaded(true);
      } else {
        setTimeout(checkWoosmapLoaded, 100);
      }
    };

    checkWoosmapLoaded();
  }, []);

  const autocompleteService = useMemo(
    () =>
      isWoosmapLoaded
        ? new window.woosmap.localities.AutocompleteService(
            localStorage.woosmapKey
          )
        : null,
    [isWoosmapLoaded]
  );

  useEffect(() => {
    if (ignoreNextQueryChangeRef.current) {
      ignoreNextQueryChangeRef.current = false;
      return;
    }

    if (query.length > 2 && autocompleteService && query !== lastQueryRef.current) {
      autocompleteService.autocomplete(
        {
          input: query,
          components: {country: ["FR"]},
          types: ["locality", "address", "postal_code", "admin_level"],
        },
        (results) => {
          if (results) {
            setSuggestions(results.localities);
            lastQueryRef.current = query;
          }
        },
        (errorCode, errorText) => {
          console.error(`Error autocomplete localities: ${errorCode}:${errorText}`);
        },
        0
      );
    } else {
      setSuggestions([]);
    }
  }, [query, autocompleteService]);

  const handleInputChange = (event) => {
    setQuery(event.target.value);
  };

  const handleSuggestionClick = (suggestion) => {
    ignoreNextQueryChangeRef.current = true;
    setQuery(suggestion.description);
    autocompleteService.getDetails(suggestion.public_id, (selectedLocality) => {
      if (onChange) {
        onChange(selectedLocality.geometry);
      }
    });
    setSuggestions([]);
  };

  const handleClearSearch = () => {
    setQuery("");
    setSuggestions([]);
    autoCompleteRef.current.focus();
  };

  const boldMatchedSubstring = (string, matchedSubstrings) => {
    if (!matchedSubstrings) return string;
    matchedSubstrings = matchedSubstrings.reverse();
    for (const substring of matchedSubstrings) {
      const char = string.substring(
        substring.offset,
        substring.offset + substring.length
      );
      string = `${string.substring(0, substring.offset)}<span class='font-arial font-bold'>${char}</span>${string.substring(
        substring.offset + substring.length
      )}`;
    }
    return string;
  };

  if (!isWoosmapLoaded) {
    return <div>Loading...</div>;
  }

  return (
    <div className="search-location-input">
      <input
        ref={autoCompleteRef}
        className={className}
        onChange={handleInputChange}
        placeholder={placeholder}
        value={query}
      />
      {suggestions.length > 0 && (
        <ul className="suggestions-list absolute z-10 rounded-lg bg-white p-4 shadow-lg shadow-slate-800/20 lg:min-w-[400px]">
          {suggestions.map((suggestion, index) => (
            <li key={index} onClick={() => handleSuggestionClick(suggestion)}>
              <div
                className="cursor-pointer p-2 font-arial text-black hover:bg-darkblue-500 hover:text-white"
                dangerouslySetInnerHTML={{
                  __html: boldMatchedSubstring(
                    suggestion.description,
                    suggestion.matched_substrings?.description
                  ),
                }}
              />
            </li>
          ))}
        </ul>
      )}
      
      
    </div>
  );
}

export default SearchLocationInput;
