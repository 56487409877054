import React from 'react';
import { Field, ErrorMessage } from 'formik';

const TextInput = ({ label, name, type = 'text', ...props }) => {
  return (
    <div className='mb-4'>
      <label htmlFor={name} className='mb-2 block font-arial text-[12px] uppercase'>
        {label}
      </label>
      <Field
        id={name}
        name={name}
        type={type}
        className='form-input mt-1 block w-full rounded-md border border-darkblue-500 p-2 shadow-sm sm:text-sm'
        {...props}
      />
      <ErrorMessage
        name={name}
        component='div'
        className='text-arial mt-1 text-red-500'
      />
    </div>
  );
};

export default TextInput;
