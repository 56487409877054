
const handleVideo = () => {

  document.addEventListener('DOMContentLoaded', () => {

    // If a user clicks on any ".video-container--image" element,
    // we hide the ".video-container--image" element and show the ".video-container--video" element

    const videoContainers = document.querySelectorAll('.video-container');

    videoContainers.forEach(function (videoContainer) {

      const videoContainerImage = videoContainer.querySelectorAll('.video-container--image');
      const videoContainerVideo = videoContainer.querySelector('.video-container--video');

      videoContainerImage.forEach(function (image) {
        image.addEventListener('click', function () {
          videoContainerImage.forEach(function (image) {
            image.classList.add('hidden');
          });
          videoContainerVideo.classList.remove('hidden');

        });
      });

    });
   
  });

}

export { handleVideo }

