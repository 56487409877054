import React from "react";

export default function RegionButton({ title, callback }) {

  const removeParanthesis = (name) => {
    // Text is in the format "Guyane (973)" or "Martinique (972)" or "Gironde (33)"

    const withoutParens = name.split(" (")[0];
    return withoutParens;
  }




  return (
    <button
      className={`rounded-full bg-[#D4EBF6] py-3 px-4 font-arial text-[14px] text-black transition-all duration-300 hover:bg-orange-500 hover:shadow-xl`}
      onClick={callback}
    >
      {removeParanthesis(title)}
    </button>
  );
}
