import React, { useEffect, useMemo, useState } from "react";
import { Home } from "../models/home";
import { Expertise } from "../models/expertise";
import { DataResponse } from "../models/data-response";
import { UIStage, useExpertiseStore } from "../store/expertises.store";
import { Category } from "../models/category";

function toRelativeUrl(absoluteUrl: string): string {
  try {
    const url = new URL(absoluteUrl);
    return url.pathname + url.search + url.hash;
  } catch (e) {
    console.error("Invalid URL provided:", e);
    return "";
  }
}

export interface UmbracoData {
  data: DataResponse | undefined;
  searchExpertises: (search: string) => void;
  searchResults: Expertise[];
  filteredData: Expertise[];
  familles: string[];
  selectCategory: (category: Category) => void;
  selectedCategory: Category | undefined;
  selectFamille: (famille: string) => void;
  selectExpertise: (expertise: Expertise) => void;
  selectedExpertise: Expertise | undefined;
  reset: () => void;
  currentStage: UIStage;
  htmlDescription: string;
  searchTerm: string;
  selectedFamille: string | undefined;
  setCurrentStage: (stage: UIStage) => void;
}

export const useUmbracoData = (nodeId: number) => {
  const [data, setData] = useState<DataResponse>();
  const [searchTerm, setSearchTerm] = useState("");
  const [htmlDescription, setHtmlDescription] = useState("");
  const [searchResults, setSearchResults] = useState<Expertise[]>([]);
  const [autoSearchResults, setAutoSearchResults] = useState<Expertise[]>([]);
  const [isInitialized, setIsInitialized] = useState(false);

  const setSelectedCategory = useExpertiseStore(
    (state) => state.setSelectedCategory
  );
  const selectedCategory = useExpertiseStore((state) => state.selectedCategory);
  const setSelectedFamille = useExpertiseStore(
    (state) => state.setSelectedFamille
  );
  const selectedFamille = useExpertiseStore((state) => state.selectedFamille);
  const setCurrentStage = useExpertiseStore((state) => state.setCurrentStage);
  const currentStage = useExpertiseStore((state) => state.currentStage);
  const setSelectedExpertise = useExpertiseStore(
    (state) => state.setSelectedExpertise
  );
  const selectedExpertise = useExpertiseStore(
    (state) => state.selectedExpertise
  );

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch(
        `/umbraco/surface/expertise/GetAllExpertises?nodeId=${nodeId}`
      );

      const data = await response.json();

      setData(data as DataResponse);
    };

    fetchData();
  }, [nodeId]);

  const filteredData = useMemo(() => {
    if (!data) return [];

    switch (currentStage) {
      case UIStage.Home:
        return [] as Expertise[];
      case UIStage.Category:
        return data.Expertises.filter(
          (x) => x && selectedCategory && x.CategoryId === selectedCategory.Id
        );
      case UIStage.Famille:
        return data.Expertises.filter(
          (x) =>
            x &&
            x.Famille?.toLowerCase().includes(selectedFamille?.toLowerCase())
        );
      case UIStage.Expertise:
        return [] as Expertise[];
    }

    return [] as Expertise[];
  }, [data, currentStage, selectedCategory, selectedFamille]);

  const searchExpertises = (search: string, autoComplete: boolean = false) => {
    setSearchTerm(search);

    const allResults = data?.Expertises.filter(
      (x) =>
        x &&
        searchTerm !== "" &&
        (x.Titre?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
          x.NomComplet?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
          x.ExplicationConcise?.toLowerCase().includes(
            searchTerm?.toLowerCase()
          ) ||
          x.Specificites?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
          x.MotsCles.some((y) =>
            y?.toLowerCase().includes(searchTerm?.toLowerCase())
          ))
    );

    if (autoComplete) {
      setAutoSearchResults(allResults as Expertise[]);
      return;
    }
    setSearchResults(allResults as Expertise[]);
  };

  const selectCategory = (category: Category) => {
    console.log({ cat: category });

    setSelectedCategory(category);
    setCurrentStage(UIStage.Category);
    setAutoSearchResults([]);
  };

  const selectFamille = (famille: string) => {
    setSelectedFamille(famille);
    setCurrentStage(UIStage.Famille);
    setAutoSearchResults([]);
  };

  const selectExpertise = (expertise: Expertise) => {
    console.log("selectExpertise", expertise.Url);
    window.history.pushState(
      { expertiseId: expertise.Id },
      expertise.Titre,
      expertise.Url
    );
    setSelectedExpertise(expertise);
    setCurrentStage(UIStage.Expertise);
    setAutoSearchResults([]);
  };

  const reset = () => {
    setSelectedCategory({} as Category);
    setSelectedFamille("");
    setSelectedExpertise({} as Expertise);
    setCurrentStage(UIStage.Home);
    setAutoSearchResults([]);
    setSearchTerm("");
  };

  const fetchDescription = async (nodeId: number) => {
    const response = await fetch(
      `/umbraco/surface/expertise/rendercontentbyid?nodeId=${nodeId}`
    );
    // The return value is a simple text/html
    const data = await response.text();
    return data;
  };

  useEffect(() => {
    if (currentStage === UIStage.Expertise || currentStage === UIStage.Home) {
      setHtmlDescription("");
    }

    if (currentStage === UIStage.Category) {
      fetchDescription(selectedCategory.Id).then((data) => {
        setHtmlDescription(data);
      });
    }

    if (currentStage === UIStage.Famille) {
      fetchDescription(selectedCategory.Id).then((data) => {
        setHtmlDescription(data);
      });
    }
    if (currentStage === UIStage.Expertise) {
      fetchDescription(selectedExpertise.Id).then((data) => {
        setHtmlDescription(data);
      });
    }
  }, [currentStage, selectedCategory, selectedFamille]);

  const [familles, setFamilles] = useState<string[]>([]);

  useEffect(() => {
    const allFamilles = data?.Expertises.reduce((acc, item) => {
      if (item.ParentId != selectedCategory.Id) return acc;

      // item.Famille is a comma separated list of familles
      const familles = item.Famille.split(",");

      familles.forEach((famille) => {
        if (!acc.includes(famille)) {
          if (famille != "") {
            acc.push(famille);
          }
        }
      });

      return acc;
    }, [] as string[]);

    setFamilles(allFamilles as string[]);
  }, [data, selectedCategory]);

  useEffect(() => {
    // Only proceed if data is present and we haven't initialized yet.
    if (!data || isInitialized) return;

    // If the relative URL of the current page is the same as one of the expertises, we select it.
    const currentUrl = toRelativeUrl(window.location.href);
    const expertise = data?.Expertises.find((x) => x.Url === currentUrl);
    const category = data?.Categories.find((cat) => cat.Url === currentUrl);

    console.log({data})

    // Auto load category
    if (category) {
      selectCategory(category);
      setCurrentStage(UIStage.Category);
      
    } else if (expertise && data && data.Categories) {
      const category = data.Categories.find(
        (x) => x.Id === expertise.CategoryId
      );
      selectCategory(category as Category);

      selectFamille(expertise.Famille);

      selectExpertise(expertise);
    }

    // Mark as initialized to avoid repeated execution.
    setIsInitialized(true);
  }, [data]); // only dependent on 'data', other dependencies are methods or constant references.

  return [
    data as DataResponse,
    searchExpertises,
    searchResults,
    filteredData,
    familles,
    selectCategory,
    selectedCategory,
    selectFamille,
    selectExpertise,
    selectedExpertise,
    reset,
    currentStage,
    htmlDescription,
    searchTerm,
    selectedFamille,
    setCurrentStage,
    autoSearchResults,
    setAutoSearchResults,
  ] as const;
};
