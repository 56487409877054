import React from 'react'

export default function LaboGallerie({images}) {
  console.log({images})
  const imageArray = JSON.parse(images)
  const [selectedImage, setSelectedImage] = React.useState(imageArray[0] ?? "")
  return (
    <div className="flex flex-wrap">
      <div className="basis-full p-4 lg:basis-3/4">
        <img src={selectedImage.Url} alt="location" className="h-[350px] w-full rounded-2xl object-cover shadow lg:h-[500px]" />
      </div>
      <div className={`
      basis-full 
      lg:basis-1/4 
      first-letter:flex 
      flex-row
      flex-wrap
      lg:flex-nowrap
      lg:flex-col 
      flex
      lg:block
      ${imageArray.length > 4 ? 'lg:overflow-y-scroll' : ''} `}>
          {imageArray.map(image => {
            const isSelectedImage = image.Url === selectedImage.Url
            return (
              <div className="h-[150px] w-1/4 basis-1/4 p-4 lg:h-1/4 lg:w-auto" key={image.Url}>
                <div style={{background: `url(${image.Thumbnail}) no-repeat center center  / cover`}}
                     onClick={(e) => { setSelectedImage(image) }}
                     className={`w-full h-full rounded-2xl cursor-pointer shadow ${isSelectedImage ? '' : 'opacity-50'}`}
                     >
                  &nbsp;
                </div>
              </div>
            )
          })}
      </div>
    </div>
  )
}
