import register from 'preact-custom-element';
import React from "react";
import { useState } from 'preact/hooks';
import {Carousel} from "react-responsive-carousel";

const ErfCarousel = ({carouselcontent}) => {
  
  console.log({carouselcontent});
  const data = JSON.parse(carouselcontent)

  // Attributes:
  // Titre
  // SousTitre
  // ImageUrl
  // Couleur 
  // LinkUrl 
  // LinkText

  return (
    <Carousel className="mt-[150px]" showArrows={true} 
              infiniteLoop={true} autoPlay={true} showThumbs={false} showStatus={false}>
      
        {data.map((item, index) => {
        
          return (
              <>
                <div key={index} className="carousel__page min-h-[60vh]">
                  <div className="carousel__image" style= {{ background: `url(${item.ImageUrl}) no-repeat center center / cover;`}}>
                    <img src={item.ImageUrl} className={"max-h-[50vh] object-cover"} alt=""/>
                  </div>
                  <div className={``} style={{ background: `${item.Couleur}`  }}>
                    <div className="container mx-auto grid grid-cols-4 gap-4">
                      <div className="col-span-3">
                        <div className="text-left text-white p-4">
                          <h2><span>{item.Titre}</span></h2>
                          <p className="carousel__subtitle">{item.SousTitre}</p>
                        </div>    
                      </div>
                      <div className="col-span-1 flex items-center justify-center">
                        <a className="cta" href={item.LinkUrl}>
                          {item.LinkText}
                        </a>
                      </div>
                    </div>
                  </div>
                  
                </div>
              </>
          )
          }
        )}
    </Carousel>
  )
}


register(ErfCarousel, 'erf-carousel', ['carouselcontent'], { shadow: false });