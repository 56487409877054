import React from "react";
import ResultatsContainer from "./resultats/resultats-container";
import Regions from "./resultats/regions";

export default function Resultats({ regions }) {
  return (
    <ResultatsContainer>
      <Regions></Regions>
    </ResultatsContainer>
  );
}
