import React from "react";
import { UIStage } from "../store/expertises.store";
import { DataResponse } from "../models/data-response";
import { Category } from "../models/category";
import { Expertise } from "../models/expertise";

type Props = {
  currentStage: UIStage;
  data: DataResponse;
  selectCategory: (category: Category) => void;
  selectFamille: (famille: string) => void;
  selectExpertise: (expertise: any) => void;
  familles: string[];
  filteredData: Expertise[];
  searchResults: Expertise[];
};

export default function CategoryButtons({
  currentStage,
  data,
  selectCategory,
  selectFamille,
  selectExpertise,
  familles,
  filteredData,
  searchResults,
}: Props) {
  return (
    <div className="flex flex-wrap justify-center gap-4 mb-8">
      {currentStage == UIStage.Home &&
        data.Categories.map((item, index) => {
          return (
            <div
              key={index}
              className="bg-[#ABBCCF] cursor-pointer 
      text-center rounded-lg 
      lg:text-base text-sm
      
      text-blue-500 flex flex-col items-center justify-center p-4 py-2"
              onClick={() => {
                selectCategory(item);
              }}
            >
              {item.Name}
            </div>
          );
        })}

      {(searchResults.length == 0 || searchResults.length > 15) && (
        <>
          {currentStage == UIStage.Category &&
            familles.map((item, index) => {
              return (
                <div
                  key={index}
                  className="bg-[#ABBCCF] cursor-pointer text-center rounded-lg text-blue-500 flex flex-col items-center justify-center p-4 py-2"
                  onClick={() => {
                    console.log("selectFamille", item);
                    selectFamille(item);
                  }}
                >
                  {item}
                </div>
              );
            })}

          {currentStage == UIStage.Famille &&
            filteredData.map((item, index) => {
              return (
                <button
                  key={index}
                  className="bg-[#ABBCCF] cursor-pointer text-center rounded-lg text-blue-500 flex flex-col items-center justify-center p-4 py-2"
                  onClick={() => selectExpertise(item)}
                >
                  {item?.Titre}
                </button>
              );
            })}
        </>
      )}

      {searchResults.length > 0 &&
        searchResults.length <= 15 &&
        searchResults.map((item, index) => {
          return (
            <button
              key={index}
              className="bg-[#ABBCCF] cursor-pointer text-center rounded-lg text-blue-500 flex flex-col items-center justify-center p-4 py-2"
              onClick={() => selectExpertise(item)}
            >
              {item?.Titre}
            </button>
          );
        })}
    </div>
  );
}
