import React from 'react'

export default function AccesRapide( {data, title} ) {

  const [isOpen, setIsOpen] = React.useState(false);

  return (
    <div className={`acces-rapide ${isOpen ? 'active' : ''}`}
    onMouseEnter={() => setIsOpen(true)}
    onMouseLeave={() => setIsOpen(false)}>
        <img src="/assets/images/eurofins-icon.svg" alt="Accès rapide" />
        <span>{title}</span>

        <div className={`absolute transition-all text-white right-0 ${isOpen ? '' : 'translate-x-full'}`}>
          {data.map((item, index) => {
            return (
              <div key={index} className="acces-rapide__dropdown-item"
              style={{
                background: `#${item.Couleur}`
              }}>
                {item.DeuxiemeIcone && 
                  <div className="flex justify-center items-center p-4 shadow">
                    <a href={item.Lien?.Url} target={item.Lien?.Target} className=''>
                      {item.Icone && <img className="w-[30px] p-0 mr-2" src={item.Icone} alt={item.Titre} />}
                    </a>
                    <a href={item.DeuxiemeLien?.Url}  target={item.DeuxiemeLien?.Target}  className=''>
                      {item.DeuxiemeIcone && <img className="w-[30px] p-0" src={item.DeuxiemeIcone} alt={item.Titre} />}
                    </a>
                  </div>
                }
                {!item.DeuxiemeIcone &&
                  <a href={item.Lien?.Url} className='flex justify-center items-center p-4 shadow'>

                    {item.Icone && <img className="w-[30px] p-0 mr-2" src={item.Icone} alt={item.Titre} />}

                    {item.Nom && <span className='text-sm'>{item.Nom}</span>}
                  </a>
                }
              </div>
          )} 
          )}

        </div>
    </div> 
  )
}
