import React, { useEffect, useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';

export default function ErfBlocMolecule({ data }) {
  const dataJson = JSON.parse(data);

  const [slides, setSlides] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    setSlides(dataJson);
    setCurrentIndex(0);
  }, [data]);

  const handleSlideChange = (index) => {
    setCurrentIndex(index);
  };

  const handleNextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % slides.length);
  };

  const handlePrevSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + slides.length) % slides.length);
  };

  const variants = {
    initial: { opacity: 0 },
    enter: { opacity: 1, transition: { duration: 0.5 } },
    exit: { opacity: 0, transition: { duration: 0.5 } },
  };

  return (
    <div className="container mx-auto"> 
      <div className="relative grid grid-cols-12 gap-6 overflow-hidden py-16">
        <div className="molecule">
          <img src="/assets/images/molecule-outline.png" className="molecule__outline" />
          <img src="/assets/images/molecule.png" className="molecule__img" />
        </div>

        <div className="col-span-12 hidden flex-col justify-center p-4 md:col-span-4 lg:flex">
          <div className="flex flex-col justify-center gap-4 border-l-2 border-white/30 py-4">
            {slides.map((slide, index) => (
              <button
                key={index}
                className={`font-sans font-bold text-[20px] relative inline-block px-4 text-left ${index === currentIndex ? 'button_molecule__active' : ''} relative`}
                onClick={() => handleSlideChange(index)}
              >
                <span className="relative mr-4 inline-block">
                  {slide.TitreNavigation}
                  <div className="mt-2 w-full border-b-2 border-white/30 pl-4"></div>
                </span>
              </button>
            ))}
          </div>
        </div>

        <div className="col-span-12 px-16 lg:col-span-8 lg:p-4 lg:px-4">
          <AnimatePresence mode="wait">
            {slides[currentIndex] && (
              <motion.div
                key={currentIndex}
                initial="initial"
                animate="enter"
                exit="exit"
                variants={variants}
              >
                <h2 className="mb-12 text-center">
                  <strong className="animate">
                    <span className="" style={{ display: 'inline-block', '--delay': '1s' }}>
                      {slides[currentIndex].TitrePrincipal}
                    </span>
                  </strong>
                </h2>
                <div dangerouslySetInnerHTML={{ __html: slides[currentIndex].Texte }} className="button_molecule__text" />
                {
                  slides[currentIndex].Url &&
                  <div>
                    <a href={slides[currentIndex].Url} target={slides[currentIndex].Target} className="cta">
                      {slides[currentIndex].Name}
                    </a>
                  </div>
                }
              </motion.div>
            )}
          </AnimatePresence>
        </div>

        {/* Mobile Navigation Arrows */}
        <div className="absolute inset-y-1/2 left-2 block -translate-y-1/2 transform lg:hidden">
          <button onClick={handlePrevSlide} className="flex h-10 w-10 items-center justify-center rounded-full p-2" style={{background: 'var(--text)', color: 'var(--background)'}}>
            &#9664;
          </button>
        </div>
        <div className="absolute inset-y-1/2 right-2 block -translate-y-1/2 transform lg:hidden">
        <button onClick={handleNextSlide} className="flex h-10 w-10 items-center justify-center rounded-full p-2" style={{background: 'var(--text)', color: 'var(--background)'}}>
            &#9654;
          </button>
        </div>
      </div>
    </div>
  );
}
