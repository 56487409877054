document.addEventListener("DOMContentLoaded", function () {
  const checkboxes = document.querySelectorAll(
    '.cti__filter input[type="checkbox"]'
  );
  const cards = document.querySelectorAll(".cti__carte");

  checkboxes.forEach(function (checkbox) {
    checkbox.addEventListener("change", function () {
      filterCards();
    });
  });

  function filterCards() {
    let activeFilters = [];

    checkboxes.forEach(function (checkbox) {
      if (checkbox.checked) {
        activeFilters.push(checkbox.value);
      }
    });

    if (activeFilters.length === 0) {
      // If no filters are active, show all cards
      cards.forEach(function (card) {
        card.style.display = "block";
      });
    } else {
      cards.forEach(function (card) {
        const cardCategories = card
          .getAttribute("data-categories")
          .split(",")
          .map((cat) => cat.trim());

        const isMatch = activeFilters.some((filter) =>
          cardCategories.includes(filter)
        );

        if (isMatch) {
          card.style.display = "block";
        } else {
          card.style.display = "none";
        }
      });
    }
  }
});
