import React, { useCallback, useEffect, useMemo } from "react";

export const Info = ({
  iconUrl,
  title,
  value,
  position,
}: {
  iconUrl: string;
  title: string;
  value: string;
  position: string;
}) => {
  const isImageSvg = iconUrl.endsWith(".svg");
  return (
    <div
      className={`col-span-1 flex items-center relative 
    ${
      position == "left" || position == "middle"
        ? "md:border-r-[2px] border-blue-500/30"
        : ""
    }
    `}
    >
      {isImageSvg && (
        <div className="absolute left-0 top-1/2 -translate-y-1/2 bg-orange-500/40 w-[50px] h-[50px] -z-10 rounded-full"></div>
      )}

      <img src={iconUrl} className="w-[60px] h-auto" />
      <div>
        <h2 className="text-[1rem] font-normal text-darkblue-500 ml-4">
          {title}
        </h2>
        <p className="text-darkblue-500 ml-4 font-bold mt-1 ">{value}&nbsp;</p>
      </div>
    </div>
  );
};
