export const translations = {
  decouvrez_dautres_specialites: {
    en: "Discover other specialties",
    fr: "Découvrez d'autres spécialités",
  },
  search_text: {
    fr: "Covid-19, Frottis, Bilan Sanguin, Test salivaire…",
    en: "Covid-19, Smear, Blood test, Saliva test…",
  },
  type_de_prelevement: {
    fr: "Type de prélevement",
    en: "Type of sampling",
  },
  dois_je_venir_a_jeun: {
    fr: "Dois-je venir à jeun ?",
    en: "Should I come fasting?",
  },
  delai_de_rendu_des_resultats_estimatif: {
    fr: "Délai de rendu des résultats estimatif",
    en: "Estimated results delivery time",
  },
  specificites: {
    fr: "Spécificités",
    en: "Specificities",
  },
  quelle_est_la_suite: {
    fr: "Quelle est la suite ?",
    en: "What's next?",
  },
  trouver_un_laboratoire: {
    fr: "Trouver un laboratoire",
    en: "Find a lab",
  },
  decouvrir_dautres_specialites: {
    fr: "Découvrir d'autres spécialités",
    en: "Discover other specialties",
  },
  decouvrir_notre_parcours_digitalise: {
    fr: "Découvrir notre parcours digitalisé",
    en: "Discover our digital journey",
  },
  delai: {
    fr: "Délai",
    en: "Delay",
  },
  oui: {
    fr: "Oui",
    en: "Yes",
  },
  non: {
    fr: "Non",
    en: "No",
  },
};

export const getLang = () => {
  const path = window.location.pathname;
  const isFrench = path.indexOf("/fr/") > -1 || path.indexOf("-fr/") > -1;
  return isFrench ? "fr" : "en";
};
