import React, { useEffect, useState } from "react";
import ErfLaboSnippet from "./ErfLaboSnippet";

function useWindowSize() {
  const [size, setSize] = useState([0, 0]);
  useEffect(() => {
    const updateSize = () => {
      setSize([window.innerWidth, window.innerHeight]);
    };
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);
  return size;
}

export default function ErfMapContainer({
  labosVisibles,
  right,
  strings,
  lang,
}) {
  const [laboSlideClass, setLaboSlideClass] = useState("hidden");
  const [mapWidth, setMapWidth] = useState(0);
  // Take the first 6 labos

  const size = useWindowSize();
  const [displayedLabos, setDisplayedLabos] = useState([]);

  useEffect(() => {
    if (size[0] < 1024) {
      setDisplayedLabos(labosVisibles.slice(0, 5));
    } else {
      setDisplayedLabos(labosVisibles.slice(0, 20));
    }
  }, [size, labosVisibles]);

  useEffect(() => {
    if (labosVisibles.length > 0) {
      setLaboSlideClass("slide-in");
      // Must be the width of the viewport without the scrollbar and minus the width of the labo slider
      setMapWidth(window.innerWidth - 500);
    } else {
      setLaboSlideClass("slide-out");
    }
  }, [labosVisibles]);

  return (
    <div className="relative">
      <div
        className={`
      flex-1 
      w-full 
      lg:max-h-[675px)] 
      order-2`}
        style={{ "--width": `${mapWidth}px` }}
      >
        {right}
      </div>
      <div className={`lg:max-h-[675px] order-1`}>
        <div
          className={`carte-slider ${laboSlideClass} 
        lg:h-full 
        lg:w-[500px] 
        bg-white overflow-y-auto 
        lg:absolute 
        left-0 top-0 bottom-0`}
        >
          {displayedLabos.map((labo, index) => {
            return (
              <ErfLaboSnippet
                labo={labo}
                key={index}
                lang={lang}
                strings={strings}
                width={size[0]}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
}
