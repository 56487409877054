function handleGeneralPopup() {

  // If the popup exists and the value is not set in the local storage
  // Popup selector: .g_popup
  // Close button selector: .g_popup__close
  // Overlay selector: .g_popup__overlay
  // Local storage key: popupClosed

  const popup = document.querySelector('.g_popup');
  const closeButton = document.querySelectorAll('.g_popup__close, .g_popup__overlay');

  // The popup is hidden by default, needs to be displayed once per session

  if (popup && !sessionStorage.getItem('popupClosed')) {
      popup.classList.add('active');
  }

  closeButton.forEach(button => {
      button.addEventListener('click', () => {
          popup.classList.remove('active');
          sessionStorage.setItem('popupClosed', true);
      });
  });

  
}

handleGeneralPopup();