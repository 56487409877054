import React, { useEffect, useMemo, useRef, useState } from "react";

export default function CarteQuiSommesNous({ mapdata, imageurl }) {
  const data = mapdata.map((item, index) => ({
    name: item.Titre,
    latLng: [
      item.Localisation.address.coordinates.lat,
      item.Localisation.address.coordinates.lng,
    ],
    location: item.Location?.coordinates,
    item: item,
    id: index,
  }));

  const [currentRegion, setCurrentRegion] = useState(null);

  const tooltips = useRef([]);

  console.log({ imageurl });

  // If the image url is not set, use the default image:  /assets/images/cartequisommesnous2.svg
  const imageUrl = useMemo(
    () =>
      imageurl == "" ? "/assets/images/cartequisommesnous2.svg" : imageurl,
    [imageurl]
  );

  useEffect(() => {
    setTimeout(() => {
      // for each element in data, find the corresponding marker and add an html tooltip
      // format is <image className="jvectormap-marker jvectormap-element" data-index="1"
      data.forEach((element, index) => {
        const marker = document.querySelector(
          `.map-marker[data-index="${index}"]`
        );
        // get the x and y coordinates of the marker
        const x = marker.getAttribute("x");
        const y = marker.getAttribute("y");

        // Add the tooltip to .jvectormap-container
        const tooltip = document.createElement("div");
        tooltip.classList.add(
          "tooltipmarker",
          "bg-white",
          "p-1",
          "px-2",
          "border-[1px]",
          "border-darkblue-500",
          "text-darkblue-500",
          "rounded-lg",
          "text-[12px]",
          "cursor-pointer"
        );
        tooltip.innerHTML = element.name;

        // Make sure the tooltips are not overlapping
        // if they are, move the tooltip to the right
        let positionX = x;
        let positionY = y - 40;

        // document.querySelector('.jvectormap-container').appendChild(tooltip);

        // is any tooltip overlapping the tooltip we just added?
        const isOverlapping = tooltips.current.some((tt) => {
          const ttCoordinates = {
            x: +tt.x,
            y: +tt.y,
            width: +tt.width,
            height: +tt.height,
          };
          const tooltipCoordinates = { x: +positionX, y: +positionY };

          const width = ttCoordinates.width;
          const height = ttCoordinates.height;

          return (
            ttCoordinates.x < tooltipCoordinates.x + width &&
            ttCoordinates.x + width > tooltipCoordinates.x &&
            ttCoordinates.y < tooltipCoordinates.y + height &&
            ttCoordinates.y + height > tooltipCoordinates.y
          );
        });

        // if the tooltip is overlapping, move it to a place where it won't overlap
        // if (isOverlapping) {
        //     positionX = + x + 50;
        //     positionY = + y - 50;
        // }

        // position the tooltip
        tooltip.style.top = positionY + "px";
        tooltip.style.left = positionX + "px";
        tooltip.style.position = "absolute";

        // when the user clicks on the tooltip, show the corresponding item
        tooltip.addEventListener("click", () => {
          setCurrentRegion(element.item);
        });

        // draw a straight line from the marker to the tooltip
        const line = document.createElement("div");

        tooltips.current.push({
          x: positionX,
          y: positionY,
          width: tooltip.offsetWidth,
          height: tooltip.offsetHeight,
        });
      });
    }, 500);
  }, []);

  const [hoveredPinIndex, setHoveredPinIndex] = useState(null);

  return (
    <>
      <div className="grid grid-cols-12 gap-4 lg:-ml-[100px]">
        <div className="col-span-12 lg:col-span-7">
          <div
            className="relative hidden lg:block"
            style={{ width: "100%" }}
          >
            <img
              src={imageUrl}
              alt=""
              className="h-full w-full object-contain"
            />
            <div className="markers absolute inset-0">
              {data.map((item, index) => (
                <div
                  className="absolute"
                  style={{
                    top: item.location ? `${item.location.y}%`: `calc(${((item.latLng[0] - 50.6) * -11).toFixed(
                      0
                    )}% + 15px)`,
                    left: item.location ?  `${item.location.x}%` : `calc(${((item.latLng[1] + 5.3) * 7.6).toFixed(0)}%)`,
                  }}
                >
                  <div
                    className="map-marker cursor-pointer"
                    data-index={index}
                    key={index}
                    onMouseEnter={() => setHoveredPinIndex(index)}
                    onMouseLeave={() => setHoveredPinIndex(null)}
                    onClick={() => setCurrentRegion(item.item)}
                  >
                    <img
                      src="/assets/images/pin.png"
                      alt=""
                      className={item.location ? "w-[40px] -translate-y-full -translate-x-1/2" : "w-[40px] -translate-y-1/2"}
                    />
                  </div>

                  <div
                    className="blockcursor-pointer absolute top-[-15px] cursor-pointer rounded-2xl border-[1px] border-darkblue-500 bg-white p-1 text-center text-[12px] text-darkblue-500 transition-all hover:bg-darkblue-500 hover:text-white"
                    style={{
                      width: item.name.length * 8 + "px",
                      left: "-" + item.name.length * 8 + "px",
                      opacity: hoveredPinIndex == index ? 1 : 0,
                    }}
                  >
                    <div className="">{item.name}</div>
                  </div>
                </div>
              ))}
            </div>
            {/* setCurrentRegion(data.find(item => item.id == code)?.item) */}
          </div>
          <div className="block lg:hidden">
            <select
              className="select-arrow relative block h-full w-full appearance-none rounded-2xl bg-darkblue-500 p-4 py-2 text-white"
              onChange={(e) => {
                setCurrentRegion(
                  data.find((item) => item.id == e.target.value)?.item
                );
              }}
            >
              {data.map((item) => (
                <option key={item.id} value={item.id}>
                  {item.name}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="col-span-12 lg:col-span-5">
          {currentRegion && (
            <div className="rounded-xl bg-orange-500 p-4">
              <div className="flex items-center justify-center">
                <div className="grow basis-1/2 text-white">
                  <h2 className="leading-tight text-white">
                    {currentRegion.Titre}
                  </h2>
                  <p className="text-[13px]">{currentRegion.SousTitre}</p>
                </div>
                <div className="basis-1/2 pl-4">
                  <img
                    src={currentRegion.Image}
                    alt="location"
                    className="h-auto w-full rounded-2xl"
                  />
                </div>
              </div>
              <div className="my-6 border-l-2 border-white pl-4">
                <div
                  className="text-white"
                  dangerouslySetInnerHTML={{ __html: currentRegion.Contenu }}
                ></div>
                {currentRegion.CTA && (
                  <a
                    href={currentRegion.CTA.Url}
                    target={currentRegion.CTA.Target}
                    className="cta !border-white !text-white"
                  >
                    {currentRegion.CTA.Name}
                  </a>
                )}
              </div>

              <div className="flex flex-wrap justify-center">
                {currentRegion.Contacts &&
                  currentRegion.Contacts.map((contact) => {
                    return (
                      <a
                        className="basis-1/2 p-4"
                        href={contact.Lien?.Url}
                        target={contact.Lien?.Target ?? "_blank"}
                      >
                        <img
                          src={contact.Photo}
                          alt="location"
                          className="h-[240px] w-full rounded-lg object-cover shadow"
                        />
                        <div className="grow text-center text-white">
                          <div className="text-arial mt-2 text-sm font-bold text-white">
                            {contact.NomComplet}
                          </div>
                          <p className="font-arial text-sm">
                            {contact.Fonction}
                          </p>
                        </div>
                      </a>
                    );
                  })}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
