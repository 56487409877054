import React, { useState } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import RadioGroup from './RadioGroup';
import TextInput from './TextInput';
import { PDFDocument, rgb } from 'pdf-lib';


const validationSchema = Yup.object({
  symptoms: Yup.string().required('Required'),
  vaccinated: Yup.string().required('Required'),
  multiplePartners: Yup.string().required('Required'),
  partnerTestedPositive: Yup.string().required('Required'),
  typeOfSample: Yup.string().required('Required'),
  analIntercourse: Yup.string().required('Required'),
  oralIntercourse: Yup.string().required('Required'),
  nomDusage: Yup.string().required('Required'),
  nomDeNaissance: Yup.string().required('Required'),
  prenom: Yup.string().required('Required'),
  dateDeNaissance: Yup.string().required('Required'),
});

const initialValues = {
  symptoms: '',
  vaccinated: '',
  multiplePartners: '',
  partnerTestedPositive: '',
  typeOfSample: '',
  analIntercourse: '',
  oralIntercourse: '',
  nomDusage: '',
  nomDeNaissance: '',
  prenom: '',
  dateDeNaissance: '',
};

const CerfaIst = ({documentUrl}) => {
  const [step, setStep] = useState(0);
  
  const handleNext = (values, name, value) => setStep(prev => {

    if (name == "vaccinated" && value !== "yes") {
      return prev + 2;
    }
    return prev + 1;
    
  });
  const handleBack = (values) => setStep(prev => {

    if (prev === 7 && values.vaccinated !== 'yes') {
      return prev - 2;
    }
    
    return prev - 1;
  });

  const isNextAvailable = (values) => {
    // The next button is available if they have selected an option in the current step
    switch (step) {
      case 0:
        return values.symptoms !== '';
      case 1:
        return values.multiplePartners !== '';
      case 2:
        return values.partnerTestedPositive !== '';
      case 3:
        return values.analIntercourse !== '';
      case 4:
        return values.oralIntercourse !== '';
      case 5:
        return values.vaccinated !== '';
      case 6:
        return values.typeOfSample !== '';
      case 7:
        return values.nomDusage !== '' && values.nomDeNaissance !== '' && values.prenom !== '' && values.dateDeNaissance !== '';
      default:
        return false;
    }
  };

  const getAgeFromDate = (date) => {
    // input format is 1983-02-07
    const [year, month, day] = date.split('-').map(Number);
    const birthDate = new Date(year, month - 1, day);
    const diff = Date.now() - birthDate.getTime();
    const ageDate = new Date(diff);
    return Math.abs(ageDate.getUTCFullYear() - 1970);
  };


  const isLastStep = step === 7;


  const steps = [
    {
      name: 'symptoms',
      label: "Avez-vous des symptômes d'infections sexuellement transmissibles (IST) ?",
      options: [
        { value: 'yes', label: 'Oui' },
        { value: 'no', label: 'Non' },
      ],
      helpText: 
        <>
          <p>Si vous présentez l’un des symptômes suivants :</p>
          <ul className='text-arial list-inside list-disc'>
            <li className="font-arial">des douleurs lorsque vous urinez ;</li>
            <li className="font-arial">des lésions, plaies ou boutons au niveau des partie génitales ou anales ;</li>
            <li className="font-arial">des douleurs au niveau dans le bas du ventre et/ou des saignements vaginaux inhabituels ;</li>
            <li className="font-arial">des sécrétions vaginales inhabituelles et/ou malodorantes ;</li>
            <li className="font-arial">d'écoulements visibles du pénis ;</li>
            <li className="font-arial">une douleur soudaine dans les testicules.</li>
          </ul>
          <p>Nous allons vous orienter vers une consultation médicale après le prélèvement.</p>
        </>
    },
    {
      name: 'multiplePartners',
      label: "Avez-vous eu plus d'un partenaire sexuel au cours des 12 derniers mois ou souhaitez-vous arrêter le préservatif avec votre nouveau ou nouvelle partenaire ?",
      options: [
        { value: 'yes', label: 'Oui' },
        { value: 'no', label: 'Non' },
      ],
      helpText: null,
    },
    {
      name: 'partnerTestedPositive',
      label: "L'un de vos partenaires a-t-il été testé positif à une ou plusieurs IST ?",
      options: [
        { value: 'yes', label: 'Oui' },
        { value: 'no', label: 'Non' },
      ],
      helpText: 
        <>
          <p>Si votre réponse aux questions 1, 2 et 3 est oui: vous nécessitez un dépistage de plusieurs 
  infections sexuellement transmissibles par une prise de sang et un prélèvement au niveau des 
  parties génitales, et/ou de l'anus et/ou de la gorge.</p>
        </>
    },
    {
      name: 'analIntercourse',
      label: "Avez-vous eu des rapports anaux non protégés par un préservatif ?",
      options: [
        { value: 'yes', label: 'Oui' },
        { value: 'no', label: 'Non' },
      ],
      helpText: 
          <p>Si vous avez répondu « oui » à cette question, vous nécessitez un prélèvement au niveau anal. Celui-ci est réalisé par auto-prélèvement.</p>
    },
    {
      name: 'oralIntercourse',
      label: "Avez-vous eu des rapports oro-génitaux ou oro-anaux non protégés par un préservatif (fellation, cunnilingus, anulingus) ?",
      options: [
        { value: 'yes', label: 'Oui' },
        { value: 'no', label: 'Non' },
      ],
      helpText: 
          <p>Si vous avez répondu « oui » à cette question, vous nécessitez un prélèvement au niveau de la 
          gorge. Celui-ci est réalisé par un professionnel de santé.</p>
    },
    {
      name: 'vaccinated',
      label: "Etes-vous vacciné contre l'hépatite B ?",
      options: [
        { value: 'yes', label: 'Oui' },
        { value: 'no', label: 'Non' },
        { value: 'unknown', label: 'Je ne sais pas' },
      ],
      helpText: 
          null
    },
    {
      name: 'typeOfSample',
      label: "Avez-vous connaissance d'un résultat d'Anticorps anti-HbS positif, signe d'une vaccination efficace dans les 12 derniers mois ?",
      options: [
        { value: 'yes', label: 'Oui' },
        { value: 'no', label: 'Non' },
        { value: 'unknown', label: 'Je ne sais pas' },
      ],
      helpText: null
    }
  ];

  const generatePDF = async (values) => {
    // Fetch the PDF as a Uint8Array
    const existingPdfBytes = await fetch(documentUrl).then((res) => res.arrayBuffer());

    // Load the PDF using pdf-lib
    const pdfDoc = await PDFDocument.load(existingPdfBytes);

    // Get the first page of the PDF
    const pages = pdfDoc.getPages();
    const firstPage = pages[0];

    // Define text options
    const fontSize = 12;
    const textColor = rgb(0, 0, 0);

    const delta = 22;
    const textDelta = - 8;

    // Add the user's name to the top left corner
    firstPage.drawText(`${values.nomDusage} `, {
      x: 147,
      y: firstPage.getHeight() + delta + textDelta - 148,
      size: fontSize,
      color: textColor,
    });

    firstPage.drawText(`${values.nomDeNaissance} `, {
      x: 170,
      y: firstPage.getHeight() + delta + textDelta - 167,
      size: fontSize,
      color: textColor,
    });

    firstPage.drawText(`${values.prenom} `, {
      x: 117,
      y: firstPage.getHeight() + delta + textDelta - 188,
      size: fontSize,
      color: textColor,
    });

    const age = getAgeFromDate(values.dateDeNaissance);

    firstPage.drawText(`${age} ans`, {
      x: 100,
      y: firstPage.getHeight() + delta + textDelta - 208,
      size: fontSize,
      color: textColor,
    });

    // Make date de naissance as dd/mm/yyyy
    const date = values.dateDeNaissance.split('-').reverse().join('/');

    firstPage.drawText(`${date} `, {
      x: 313,
      y: firstPage.getHeight() + delta + textDelta - 208,
      size: fontSize,
      color: textColor,
    });

    firstPage.drawText(`x`, {
      x: values.symptoms === 'yes' ?  454 : 493,
      y: firstPage.getHeight() + delta - 241,
      size: fontSize,
      color: textColor,
    });

    firstPage.drawText(`x`, {
      x: values.multiplePartners === 'yes' ?  386 : 433,
      y: firstPage.getHeight() + delta - 406,
      size: fontSize,
      color: textColor,
    });

    firstPage.drawText(`x`, {
      x: values.partnerTestedPositive === 'yes' ?  417 : 468,
      y: firstPage.getHeight() + delta - 439,
      size: fontSize,
      color: textColor,
    });

    firstPage.drawText(`x`, {
      x: values.analIntercourse === 'yes' ?  406 : 447,
      y: firstPage.getHeight() + delta - 512,
      size: fontSize,
      color: textColor,
    });

    firstPage.drawText(`x`, {
      x: values.oralIntercourse === 'yes' ?  244 : 289,
      y: firstPage.getHeight() + delta - 584,
      size: fontSize,
      color: textColor,
    });

    firstPage.drawText(`x`, {
      x: values.vaccinated === 'yes' ?  275 : values.vaccinated === 'no' ?  330 : 388,
      y: firstPage.getHeight() + delta - 643,
      size: fontSize,
      color: textColor,
    });

    firstPage.drawText(`x`, {
      x: values.typeOfSample === 'yes' ?  305 : values.typeOfSample === 'no' ?  360 : 417,
      y: firstPage.getHeight() + delta - 683,
      size: fontSize,
      color: textColor,
    });

    // Serialize the PDFDocument to bytes (a Uint8Array)
    const pdfBytes = await pdfDoc.save();

    // Trigger the download
    const blob = new Blob([pdfBytes], { type: 'application/pdf' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = 'FormulaireIST.pdf';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  
  return (
    <div className='my-12 mx-auto max-w-[900px] px-4'>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          console.log(values);
        }}
      >
        {({ errors, touched, values }) => {
          const currentStep = steps[step];
          
          return (
            <Form>
              {!isLastStep &&
                <RadioGroup
                  name={currentStep.name}
                  label={currentStep.label}
                  options={currentStep.options}
                  errors={errors}
                  touched={touched}
                  displayHelpText={true}
                  helpText={currentStep.helpText}
                  onNext={(name, value) => handleNext(values, name, value)}
                />
              }
              {isLastStep &&
                <>
                <h2 className='mb-2'> 
                  Informations personnelles
                </h2>
                <div className="grid grid-cols-2 gap-4">

                  <TextInput
                    label='Nom d’usage'
                    name='nomDusage'
                    type='text'
                    placeholder='Entrez votre nom d’usage'
                  />
                  <TextInput
                    label='Nom de naissance'
                    name='nomDeNaissance'
                    type='text'
                    placeholder='Entrez votre nom de naissance'
                  />
                  <TextInput
                    label='Prénom'
                    name='prenom'
                    type='text'
                    placeholder='Entrez votre prénom'
                  />
                  <TextInput
                    label='Date de naissance'
                    name='dateDeNaissance'
                    type='date'
                  />
                </div>
                <div className='mt-2 border-l-4 border-pink-500 bg-lightpink-300 p-4 text-center font-arial text-[18px] text-black'>
                  <span className='font-arial'>Merci d'imprimer ce questionnaire et de l’apporter le jour de votre rendez-vous</span>
                  <br />
                  <span className='font-arial font-bold'>Ou</span>
                  <br />
                  <span className='font-arial'>De le déposer sur votre espace patient eurofins e-Clinical ou Doctolib <strong className='font-arial'>avant</strong> votre rendez-vous</span>
                  <br />
                  {/* margin: 20px auto;
                      width: 80%;
                      z-index: 1000;
                      position: relative;
                      border-bottom: 1px solid #aaa; */}
                  <hr className="my-[20px] mx-auto w-4/5 border-b border-b-[#aaa]" />
                  <span className='font-arial'>
                    L'ensemble des données renseignées sur ce formulaire ne sont ni enregistrées ni conservées sur notre site, elles servent uniquement à générer le PDF. 
                    <br />
                    Vous pouvez également remplir ce formulaire imprimé à la main.
                  </span>
                </div> 
                </>
              }
              <div className='mt-4 flex justify-between'>
                {step > 0 && <button type="button" disabled={step == 0} className={`cta ${step == 0 ? 'opacity-50 cursor-not-allowed' : ''}`} onClick={() => handleBack(values)}>Retour</button>}
                {/* {step <= steps.length - 1 && <button disabled={!isNextAvailable(values)} type="button" className={`cta ${!isNextAvailable(values) ? 'opacity-50 cursor-not-allowed' : ''}`} onClick={() => handleNext(values)}>Question suivante</button>} */}
                {step === steps.length && 
                  <button 
                    type="submit" 
                    disabled={!isNextAvailable(values)} 
                    onClick={() => generatePDF(values)}
                    className={`cta ${!isNextAvailable(values) ? 'opacity-50 cursor-not-allowed' : ''}`}>
                      Télécharger le PDF
                  </button>}
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default CerfaIst;
