const handleNavigation = () => {
  document.addEventListener("DOMContentLoaded", () => {
    // If they click on the .bio-navigation__burger button, the body will have a .nav-open class
    const burger = document.querySelectorAll(".bio-navigation__burger");

    burger.forEach(function (burger) {
      burger.addEventListener("click", function () {
        document.body.classList.toggle("nav-open");
      });
    });

    const navLinkBacks = document.querySelectorAll(
      ".bio-megamenu__nav-sublink--back"
    );

    navLinkBacks.forEach(function (navLinkBack) {
      navLinkBack.addEventListener("click", function (e) {
        if (e.clientX < 40) {
          e.preventDefault();

          // 4 parent's up, then the .open child, then remove .open
          navLinkBack.parentElement.parentElement.parentElement.parentElement.children[0].classList.remove(
            "open"
          );
          document
            .querySelector(".bio-megamenu__nav")
            .classList.remove("active");
        }
      });
    });

    const navLinks = document.querySelectorAll(".bio-megamenu__nav-link");

    navLinks.forEach(function (navLink) {
      navLink.addEventListener("click", function (e) {
        // Is it already .active or .open?
        if (
          navLink.classList.contains("active") ||
          navLink.classList.contains("open")
        ) {
          return;
        }

        // Does it have .bio-megamenu__nav-sublist sibling?
        if (navLink.nextElementSibling.nextElementSibling) {
          e.preventDefault();

          navLinks.forEach(function (navLink) {
            navLink.classList.remove("open");
            navLink.classList.remove("active");
          });
          navLink.classList.add("open");
          navLink.parentElement.parentElement.classList.add("active");
        }
      });
    });

    // if the element .goToActus is clicked, smooth scroll to the element .actus-home element
    const goToActus = document.querySelector(".goToActus");
    if (goToActus) {
      goToActus.addEventListener("click", function (e) {
        e.preventDefault();
        document
          .querySelector(".goToActus")
          .parentElement.parentElement.nextElementSibling.scrollIntoView({
            behavior: "smooth",
          });
      });
    }

    const goToSoins = document.querySelector(".goToSoins");
    if (goToSoins) {
      goToSoins.addEventListener("click", function (e) {
        e.preventDefault();
        document.querySelector(".goToSoins").nextElementSibling.scrollIntoView({
          behavior: "smooth",
        });
      });
    }

    const nextSections = document.querySelectorAll(".goToNextSection");
    if (nextSections.length) {

      nextSections.forEach(goToNextSection => {

        goToNextSection.addEventListener("click", function (e) {
          e.preventDefault();

          const isNextSiblingSection =
            goToNextSection.nextElementSibling?.tagName === "SECTION" || 
            goToNextSection.parentElement.nextElementSibling?.tagName === "SECTION";

          if (isNextSiblingSection) {
            (goToNextSection.nextElementSibling || goToNextSection.parentElement.nextElementSibling).scrollIntoView({
              behavior: "smooth",
            });
            return;
          }

          // Go through parents until you find the "section" parent (not class, the tag), and get the next section
          nextSection = goToNextSection;
          while (nextSection?.tagName !== "SECTION") {
            nextSection = nextSection.parentElement;
          }
          (nextSection.nextElementSibling || nextSection.parentElement.nextElementSibling).scrollIntoView({
            behavior: "smooth",
          });
        });
      })
    }

    // On load and window resize, ensure that the top padding of the body is equal to the height of ".bio-navigation"

    const resizeNavigation = () => {
      const bioNavigation = document.querySelector(".bio-navigation");
      const bioNavigationHeight = bioNavigation.offsetHeight;
      console.log({ bioNavigationHeight });
      document.body.style.paddingTop = bioNavigationHeight + "px";
    };

    resizeNavigation();

    window.addEventListener("resize", resizeNavigation);
  });
};

export { handleNavigation };
