import "./index.scss";
import { ErfTitle } from "./components/ErfTitle";
import { ErfCarousel } from "./components/ErfCarousel";
import ErfCarte from "./components/ErfCarte";
import ReactDOM from "react-dom/client";
import { handleCarousel } from "./scripts/carousel";
import { handleVideo } from "./scripts/video";
import { handleNavigation } from "./scripts/navigation";
import WOW from "wow.js/dist/wow.min";
import "../node_modules/wow.js/css/libs/animate.css";
import { $, jQuery } from "jquery";
import slick from "slick-carousel";
import CarteQuiSommesNous from "./components/CarteQuiSommesNous";
import AccesRapide from "./components/AccesRapide";
import LaboGallerie from "./components/LaboGallerie";
import Expertises from "./components/Expertises";
import "./scripts/filter-list";
import Resultats from "./components/Resultats";
import ErfCarouselDigital from "./components/ErfCarouselDigital";
import ErfBlocMolecule from "./components/ErfBlocMolecule";
import ErfMatrix from "./components/ErfMatrix";

handleCarousel();
handleVideo();

new WOW().init();

const renderReactElement = (element, containerSelector) => {
  const allContainers = document.querySelectorAll(containerSelector);
  // Does the container element exist?
  if (allContainers.length > 0) {
    allContainers.forEach((container) => {
      //ReactDOM.render(element, container);
      ReactDOM.createRoot(container).render(element);
    });
  }
};

const elementExists = (selector) => {
  return document.querySelector(selector) !== null;
};

if (elementExists(".accesrapide")) {
  const data = document
    .querySelector(".accesrapide")
    ?.getAttribute("data-data");
  const title = document
    .querySelector(".accesrapide")
    ?.getAttribute("data-title");
  const dataJson = JSON.parse(data);
  renderReactElement(
    <AccesRapide data={dataJson} title={title} />,
    ".accesrapide"
  );
}

if (elementExists(".react-regions")) {
  console.log("react-regions");
  renderReactElement(<Resultats />, ".react-regions");
}

if (elementExists(".matrix-anim")) {
  renderReactElement(<ErfMatrix />, ".matrix-anim");
}

if (elementExists(".erf-carte")) {
  const elm = document.querySelector(".erf-carte");
  const data = elm.getAttribute("data-gkey");
  const labData = elm.getAttribute("data-data");
  const langue = elm.getAttribute("data-lang");
  const fertilite = elm.getAttribute("data-fertilite");
  const mode = elm.getAttribute("data-mode");
  const center = elm.getAttribute("data-center");
  const laboId = elm.getAttribute("data-laboid");
  setTimeout(() => {
    renderReactElement(
      <ErfCarte gkey={data} labData={labData} langue={langue} fert={fertilite} mode={mode} laboId={+laboId} centerString={center} />,
      ".erf-carte"
    );
  }, 500);
}

if (elementExists(".carousel-digital")) {
  const data = document
    .querySelector(".carousel-digital")
    ?.getAttribute("data-props");
  renderReactElement(<ErfCarouselDigital data={data} />, ".carousel-digital");

}

if (elementExists(".bloc-molecule")) {
  const data = document
    .querySelector(".bloc-molecule")
    ?.getAttribute("data-props");
  renderReactElement(<ErfBlocMolecule data={data} />, ".bloc-molecule");

}


if (elementExists(".labo-gallery")) {
  const data = document
    .querySelector(".labo-gallery")
    ?.getAttribute("data-images");
  renderReactElement(<LaboGallerie images={data} />, ".labo-gallery");
}

if (elementExists(".search-expertises")) {
  const element = document.querySelector(".search-expertises");
  const id = element?.getAttribute("data-currentpageid");

  console.log(id);

  renderReactElement(<Expertises id={id} />, ".search-expertises");
}

if (elementExists(".erf-cerfaist")) {
  const url = document.querySelector(".erf-cerfaist")?.getAttribute("data-url");
  renderReactElement(<CerfaIst documentUrl={url} />, ".erf-cerfaist");
}

// get the data-mapdata from ".carte-qui-sommes-nous"
const carteQuiSommesNousData = document
  .querySelector(".carte-qui-sommes-nous")
  ?.getAttribute("data-mapdata");
const carteQuiSommesNousImage = document
  .querySelector(".carte-qui-sommes-nous")
  ?.getAttribute("data-imageurl");
if (carteQuiSommesNousData) {
  renderReactElement(
    <CarteQuiSommesNous
      mapdata={JSON.parse(carteQuiSommesNousData)}
      imageurl={carteQuiSommesNousImage}
    />,
    ".carte-qui-sommes-nous"
  );
} else {
  renderReactElement(<CarteQuiSommesNous />, ".carte-qui-sommes-nous");
}

// On DOMContentLoaded
document.addEventListener("DOMContentLoaded", () => {
  // when .odometer comes in viewport, start counting
  const odometers = document.querySelectorAll(".odometer");

  // Track viewport
  const options = {
    rootMargin: "0px 0px 0px 0px",
    threshold: 0.1,
  };

  const callback = function (entries) {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        // Get the data-stat
        const stat = entry.target.getAttribute("data-stat");

        // Is the .odometer-value deep child value = 0?
        if (entry.target.querySelector(".odometer-value").innerHTML === "0") {
          entry.target.innerHTML = stat;
        }
      }
    });
  };

  const observer = new IntersectionObserver(callback, options);
  odometers.forEach(function (odometer) {
    observer.observe(odometer);
  });
});

handleNavigation();


import "./scripts/generalpopup";
import "./scripts/stabylo";
import CerfaIst from "./components/cerfaist/CerfaIst";

export { ErfTitle, ErfCarousel, AccesRapide, ErfCarte, $, jQuery, slick };
