import React from 'react'

export default function ErfMarker({lat, lng, title, onClick, selected, url}) {
  return (
    <div className={`text-white text-center p-2 -translate-x-1/2 -translate-y-full rounded-sm` }
            lat={lat} 
            lng={lng}
            style={{
              width: '40px',
              height: '40px',
              zIndex: '10',
            }}
            onClick={onClick}
        >
          {
            selected ? 
              <img className='w-[40px] relative -left-1/2 animate-pin' src="/assets/images/pin-orange.png" /> :
              <img className='w-[40px] -translate-x-1/2 relative left-1/2  -translate-y-1/2 top-1/2' src="/assets/images/pin.png" />
          }
    </div > 
  )
}
  