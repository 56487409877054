import React, { useEffect, useState } from 'react';

function Slide({ slide }) {
  const textWithoutPTags = slide.Texte.replace(/<p>/g, '').replace(/<\/p>/g, '');

  return (
    <a href={slide.Url} target={slide.Target}
      className="mr-[20px] inline-block flex-1 transform flex-col justify-between overflow-hidden rounded-[20px] bg-white p-8 text-center shadow-lg shadow-slate-800/30 transition-transform duration-300 ease-in-out hover:scale-105 lg:w-[200px]"
    >
      <img src={slide.Image} alt=""
        className="mx-auto aspect-square w-[100px] rounded-full" />
      <div
        className="bloc_digital__slide mt-4 text-[15px]"
        dangerouslySetInnerHTML={{ __html: textWithoutPTags }} />
    </a>
  );
}

export default function ErfCarouselDigital({ data }) {
  const dataJson = JSON.parse(data);

  const [slides, setSlides] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isTransitioning, setIsTransitioning] = useState(false);

  useEffect(() => {
    setSlides(dataJson);
  }, [data]);

  const handleNext = () => {
    if (isTransitioning) return; // Prevent new transitions during an ongoing transition

    setIsTransitioning(true);
    setCurrentIndex(prevIndex => prevIndex + 1);

    setTimeout(() => {
      setSlides(prevSlides => {
        const newSlides = [...prevSlides];
        const firstSlide = newSlides.shift();
        newSlides.push(firstSlide);
        return newSlides;
      });

      setCurrentIndex(prevIndex => prevIndex - 1); // Adjust the currentIndex to account for the shifted array
      setIsTransitioning(false);
    }, 300); // Match this duration with your CSS transition duration
  };

  return (
    <>
      <div className="relative hidden overflow-hidden p-4 lg:block">
        <div className={`relative w-auto py-4 pr-4 flex transition-transform duration-300 ease-in-out ${isTransitioning ? '' : 'transition-none'}`}
          style={{
            transform: `translateX(-${currentIndex * 220}px)`,
            minWidth: `${slides.length * 220}px`
          }}
        >
          {slides.map((slide, index) => (
            <Slide key={index} slide={slide} />
          ))}
        </div>
        <div className="bloc_digital__carouseloverlay pointer-events-none absolute inset-0" />
        <button onClick={handleNext} className="absolute right-0 top-1/2 h-8 w-8 -translate-y-1/2 transform rounded-full bg-[#13145D] p-2 text-white shadow-lg">
          <div className="relative right-[1px] mx-auto h-[8px] w-[8px] rotate-45 transform border-t-2 border-r-2 border-white" />
        </button>
      </div>

      <div className="grid grid-cols-1 gap-4 p-4 md:grid-cols-2 lg:hidden"> 
        {slides.map((slide, index) => (
            <Slide key={index} slide={slide} />
        ))}
      </div>


    </>
  );
}
